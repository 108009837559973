<template>
  <div class="dashboard">
    <div class="flex">
      <div>
        <Menu />
      </div>
      <div class="w-full relative">
        <div
          class="bg-nColorLightYellow border border-nColorDarkYellow rounded-3xl m-10"
        >
          <div class="flex gap-4">
            <div class="grid justify-between p-8">
              <div>
                <p class="font-ZuumeSemiBold text-6xl">hello!</p>
                <p class="font-InterSemiBold">
                  Bringing together stakeholders from different domains to
                  engage, promote, entertain and play. Let's start planning
                  events together.
                </p>
              </div>
              <div class="flex items-end">
                <button
                  class="font-ZuumeMedium border border-black px-6 py-1 mt-4 rounded-3xl text-2xl"
                  @click.prevent="discoverGames()"
                >
                  Discover Games
                </button>
              </div>
            </div>
            <div class="flex items-end pr-10 relative">
              <p class="absolute top-6 right-6 font-InterSemiBold">
                {{ currentDate }}
              </p>
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/diy/home/home_banner.webp"
                alt="Live Events"
                width="1024"
                height="617"
              />
            </div>
          </div>
        </div>
        <div
          class="bg-white border border-nColorLightGray rounded-3xl p-8 m-10 grid justify-items-center"
        >
          <p class="font-InterMedium text-2xl">
            You have no Game Jams planned yet
          </p>
          <p class="font-InterSemiBold text-sm text-nColorMediumGray my-2">
            Let's plan a super fun Game Jam with your peers - it's going to be a
            blast!
          </p>
          <div>
            <button
              class="font-ZuumeMedium bg-black text-white px-10 py-1 mt-4 rounded-3xl text-3xl"
              @click.prevent="createGameJam()"
            >
              + Create a new Game Jam
            </button>
          </div>
        </div>
        <div class="absolute bottom-6 w-full"><Footer /></div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Dashboard/DesktopMenu.vue";
import Footer from "@/components/Dashboard/DesktopFooter.vue";

export default {
  name: "Dashboard",
  components: { Menu, Footer },
  data() {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = currentDate.getFullYear();

    return {
      currentDate: `${day}-${month}-${year}`,
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    discoverGames() {
      console.log("discover games");
    },
    createGameJam() {
      this.$router.push({
        name: "CreateGameJam",
        params: {
          game_jam_id: -1,
          step: "step1",
        },
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.dashboard {
  height: 100dvh;
}
</style>
