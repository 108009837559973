<template>
  <div class="w-full grid justify-items-center">
    <p class="text-nColorCopyrightGray text-xs font-InterLight">
      © Copyright 2023 Texperia Techsolutions Pvt Ltd. All rights reserved.
    </p>
    <p class="text-nColorOrange font-InterMedium text-sm underline">
      <a :href="mailToLink">Contact us</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "DesktopFooter",
  data() {
    return {
      recipient: "business@playda.app",
    };
  },
  computed: {
    mailToLink() {
      return `mailto:${this.recipient}`;
    },
  },
  methods: {},
};
</script>

<style scoped></style>