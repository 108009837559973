<template>
  <div>
    <Desktop class="hidden md:block" />
    <Mobile class="block md:hidden" />
  </div>
</template>

<script>
import Desktop from "@/components/Dashboard/Desktop.vue";
import Mobile from "@/components/Dashboard/Mobile.vue";

export default {
  name: "Dashboard",
  components: { Desktop, Mobile },
  data() {
    return {};
  },
  created() {},

  computed: {},
};
</script>
  
<style scoped></style>
