<template>
  <div class="menu px-6 pt-6">
    <div class="flex justify-between items-center">
      <div>
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda_logo.webp"
          alt="Playda Logo"
          width="64"
          height="23"
        />
      </div>
      <div>
        <img
          src="https://playda.s3.ap-south-1.amazonaws.com/diy/common/menu.webp"
          alt="Menu Icon"
          width="24"
          height="16"
          @click.prevent="showMenu = true"
        />
      </div>
    </div>
    <div
      class="bg-white fixed top-0 left-0 w-full h-screen z-10 p-6"
      v-if="showMenu"
    >
      <div class="flex justify-between items-center">
        <div>
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda_logo.webp"
            alt="Playda Logo"
            width="64"
            height="23"
          />
        </div>
        <div>
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/diy/common/menu-close.webp"
            alt="Menu Icon"
            width="24"
            height="16"
            @click.prevent="showMenu = false"
          />
        </div>
      </div>
      <div class="grid gap-4 mt-4">
        <div class="bg-nColorBGWhite p-4 rounded-xl flex gap-2 items-center">
          <div>
            <img
              src="https://playda.s3.ap-south-1.amazonaws.com/diy/home/upcoming_event.webp"
              alt="Live Events"
              width="40"
              height="43"
            />
          </div>
          <div>
            <p class="font-RobotoMedium">Live Game Jams</p>
            <p class="font-RobotoBlack">00</p>
          </div>
        </div>
        <div class="bg-nColorBGWhite p-4 rounded-xl flex gap-2 items-center">
          <div>
            <img
              src="https://playda.s3.ap-south-1.amazonaws.com/diy/home/completed_events.webp"
              alt="Completed Events"
              width="40"
              height="43"
            />
          </div>
          <div>
            <p class="font-RobotoMedium">Completed Game Jams</p>
            <p class="font-RobotoBlack">00</p>
          </div>
        </div>
        <div class="bg-nColorBGWhite p-4 rounded-xl flex gap-2 items-center">
          <div>
            <img
              src="https://playda.s3.ap-south-1.amazonaws.com/diy/home/cancelled_events.webp"
              alt="Cancelled Events"
              width="40"
              height="43"
            />
          </div>
          <div>
            <p class="font-RobotoMedium">Cancelled Game Jams</p>
            <p class="font-RobotoBlack">00</p>
          </div>
        </div>
        <div class="bg-nColorBGWhite p-4 rounded-xl flex gap-2 items-center">
          <div>
            <img
              src="https://playda.s3.ap-south-1.amazonaws.com/diy/home/billing_invoices.webp"
              alt="Billing and Invoices"
              width="40"
              height="43"
            />
          </div>
          <div>
            <p class="font-RobotoMedium">Billing & Invoices</p>
            <p class="font-RobotoBlack">00</p>
          </div>
        </div>
      </div>
      <div class="fixed bottom-12 w-10/12">
        <div class="grid justify-items-center">
          <p class="font-InterBold text-xl text-nColorOrange">{{ username }}</p>
          <p class="font-InterMedium text-nColorDarkGray opacity-60 text-sm">
            {{ email }}
          </p>
          <div>
            <button
              class="font-ZuumeMedium text-xl border border-black px-6 mt-4 rounded-2xl"
              @click.prevent="signOut()"
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesktopMenu",
  data() {
    return {
      username: this.$store.getters.profile.name,
      email: this.$store.getters.profile.email,
      showMenu: false,
    };
  },
  methods: {
    goToDashboard() {
      this.$router.push({
        name: "Dashboard",
      });
    },
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style scoped></style>