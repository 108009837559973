<template>
  <div class="dashboard">
    <div class="grid">
      <div>
        <Menu />
      </div>
      <div class="p-4">
        <div
          class="bg-nColorLightYellow border border-nColorDarkYellow rounded-3xl"
        >
          <div class="grid gap-4 p-4">
            <div>
              <div>
                <div class="flex justify-between w-full">
                  <p class="font-ZuumeSemiBold text-5xl">hello!</p>
                  <p class="font-InterSemiBold">
                    {{ currentDate }}
                  </p>
                </div>
                <p class="font-InterSemiBold text-sm">
                  Bringing together stakeholders from different domains to
                  engage, promote, entertain and play. Let's start planning
                  events together.
                </p>
              </div>
            </div>
            <div
              class="flex justify-center items-end relative px-8 border-b border-nColorBorderOrange"
            >
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/diy/home/home_banner.webp"
                alt="Live Events"
                width="1024"
                height="617"
              />
            </div>
            <div class="flex justify-center">
              <button
                class="w-full font-ZuumeMedium border border-black py-1 rounded-3xl text-2xl"
                @click.prevent="discoverGames()"
              >
                Discover Games
              </button>
            </div>
          </div>
        </div>
        <div
          class="bg-white border border-nColorLightGray rounded-3xl mt-4 grid justify-items-center p-4"
        >
          <p class="font-InterMedium text-lg">
            You have no Game Jams planned yet
          </p>
          <p
            class="font-InterSemiBold text-xs text-nColorMediumGray my-2 text-center"
          >
            Let's plan a super fun Game Jam with your peers - it's going to be a
            blast!
          </p>
          <div class="w-full">
            <button
              class="font-ZuumeMedium bg-black text-white w-full py-1 mt-4 rounded-3xl text-xl"
              @click.prevent="createGameJam()"
            >
              + Create a new game jam
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Dashboard/MobileMenu.vue";

export default {
  name: "Dashboard",
  components: { Menu },
  data() {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = currentDate.getFullYear();

    return {
      currentDate: `${day}-${month}-${year}`,
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    discoverGames() {
      console.log("discover games");
    },
    createGameJam() {
      this.$router.push({
        name: "CreateGameJam",
        params: {
          game_jam_id: -1,
          step: "step1",
        },
      });
    },
  },
};
</script>

<style scoped lang="postcss"></style>

