<template>
  <div class="menu bg-white p-8 relative w-72">
    <div class="p-8">
      <img
        src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda_logo.webp"
        alt="Playda Logo"
        width="150"
        height="53"
      />
    </div>
    <div class="grid gap-4">
      <div class="bg-nColorBGWhite p-4 rounded-xl flex gap-2 items-center">
        <div>
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/diy/home/upcoming_event.webp"
            alt="Live Events"
            width="40"
            height="43"
          />
        </div>
        <div>
          <p class="font-RobotoMedium">Live Game Jams</p>
          <p class="font-RobotoBlack">00</p>
        </div>
      </div>
      <div class="bg-nColorBGWhite p-4 rounded-xl flex gap-2 items-center">
        <div>
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/diy/home/completed_events.webp"
            alt="Completed Events"
            width="40"
            height="43"
          />
        </div>
        <div>
          <p class="font-RobotoMedium">Completed Game Jams</p>
          <p class="font-RobotoBlack">00</p>
        </div>
      </div>
      <div class="bg-nColorBGWhite p-4 rounded-xl flex gap-2 items-center">
        <div>
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/diy/home/cancelled_events.webp"
            alt="Cancelled Events"
            width="40"
            height="43"
          />
        </div>
        <div>
          <p class="font-RobotoMedium">Cancelled Game Jams</p>
          <p class="font-RobotoBlack">00</p>
        </div>
      </div>
      <div class="bg-nColorBGWhite p-4 rounded-xl flex gap-2 items-center">
        <div>
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/diy/home/billing_invoices.webp"
            alt="Billing And Invoices"
            width="40"
            height="43"
          />
        </div>
        <div>
          <p class="font-RobotoMedium">Billing & Invoices</p>
          <p class="font-RobotoBlack">00</p>
        </div>
      </div>
    </div>
    <div class="absolute bottom-8 w-9/12">
      <div class="grid justify-items-center">
        <p class="font-InterBold text-xl text-nColorOrange">{{ username }}</p>
        <p class="font-InterMedium text-nColorDarkGray opacity-60 text-sm">
          {{ email }}
        </p>
        <div>
          <button
            class="font-ZuumeMedium border border-black px-6 mt-4 rounded-xl"
            @click.prevent="signOut()"
          >
            Sign Out
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesktopMenu",
  data() {
    return {
      username: this.$store.getters.profile.name,
      email: this.$store.getters.profile.email,
    };
  },
  methods: {
    goToDashboard() {
      this.$router.push({
        name: "Dashboard",
      });
    },
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style scoped>
.menu {
  height: 100dvh;
}
</style>